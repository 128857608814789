/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthInfo } from "@propelauth/react";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import { authorizedApiClient } from "../services/config";

const AuthProvider = () => {
  const location = useLocation();
  const authInfo: any = useAuthInfo();
  const authInfoRef = useRef(authInfo);
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    authInfoRef.current = authInfo;
  }, [authInfo]);

  useEffect(() => {
    if (authInfoRef.current.accessToken) {
      authorizedApiClient.interceptors.request.use(
        (config: any) => {
          // If the user is logged in, add the token to the Authorization header
          config.headers.Authorization = `Bearer ${authInfoRef.current.accessToken}`;
          return config;
        },
        (error) => Promise.reject(error)
      );
      setFirstLoad(false);
    }
  }, [authInfoRef.current.accessToken]);

  useEffect(() => {
    if (location.pathname.includes("/onboarding")) {
      document.title = "Onboarding | Hyperengage";
    } else if (location.pathname.includes("/headquarters")) {
      document.title = "Headquarters | Hyperengage";
    } else if (location.pathname.includes("/signals")) {
      document.title = "Signals | Hyperengage";
    } else if (location.pathname.includes("/inbox")) {
      document.title = "Inbox | Hyperengage";
    } else if (location.pathname.includes("/settings")) {
      document.title = "Settings | Hyperengage";
    }
  }, [location.pathname]);

  if (firstLoad) {
    return (
      <LoadingOverlay
        loaderProps={{ size: "lg" }}
        c="main.5"
        visible
        overlayBlur={2}
      />
    );
  }
  return <Outlet />;
};

export default AuthProvider;
