/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React from "react";
import { dateFormat, getValueForRow } from "../../utils/tableUtils";
import { Tooltip } from "@mantine/core";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const DateCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return (
      <Tooltip label={format(zonedTimeToUtc(new Date(value?.replace(" ", "T")), "UTC"), "dd MMM yyyy h:mm aa")} position="bottom">
      <div className="overflow-hidden text-ellipsis whitespace-nowrap overflow-hidden">
      {dateFormat(column.key, value)}
      </div>
    </Tooltip>
    );
  },
);

export default DateCell;
