/* eslint-disable @typescript-eslint/no-unused-vars */
import { TrashIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import { Select } from "@mantine/core";
import SingleSelectDropdown from "../ui/dropdown/SingleSelectDropdown";
import BooleanFilter from "./BooleanFilter";
import DateFilter from "./DateFilter";
import MetricFilter from "./MetricFilter";
import NumberFilter from "./NumberFilter";
import StringFilter from "./StringFilter";
import MemberFilter from "./MemberFilter";

type Props = {
  onRemove: (currentKey: any, filterId?: any) => void;
  currentKey: number;
  initField: any;
  initOps: any;
  initValues: any;
  traits: any[],
  metrics: any[],
  filterId: number,
  time_window?: string;
  single_time_window?: string;
  entity?: string;
  onCompleteFilter: (field: any,
    operator: any,
    value: any,
    type: any,
    entityType: any,
    id: number,
    filterId: number,
    timeWindow?: string) => void;
};

const FilterRow = (
  {
    initField,
    time_window,
    single_time_window,
    initOps,
    initValues,
    entity,
    onRemove,
    traits,
    metrics,
    currentKey,
    onCompleteFilter,
    filterId,
  }: Props,
) => {
  const [field, setField] = useState<any>(
    initField || {
      name: "", id: -1, entity_type: "column", data_type: "string",
    },
  );

  const [timeWindow, setTimeWindow] = useState<any>(
    single_time_window || time_window,
  );

  const timeframes = useMemo(() => ([
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 90 days",
    "Last 180 days",
    "Last 365 days",
    "All time",
  ]).map((key) => ({ label: key, value: key.toLowerCase().split(" ").join("_") })), []);

  const onCompleteRow = (
    operator: any,
    value: any,
    type: any,
    entityType: any,
    tw?: string,
  ) => {
    if (field?.attribute_type === "metric_traits") {
      onCompleteFilter(
        field.name,
        operator,
        value,
        type,
        entityType,
        currentKey,
        filterId,
        tw,
      );
    } else {
      onCompleteFilter(
        field.name,
        operator,
        value,
        type,
        entityType,
        currentKey,
        filterId,
      );
    }
  };

  const renderFilter = () => {
    if (field?.attribute_type === "metric_traits") {
      return (
        <>
          <MetricFilter
            key={`${field?.name} + ${filterId}`}
            fieldSelect={!field?.name}
            entity={field?.entity_type}
            handleChange={onCompleteRow}
            timeWindow={timeWindow}
            op={initOps}
            value={field?.name === initField?.name && initValues}
          />
          <Select
            value={timeWindow}
            data={timeframes}
            size="sm"
            classNames={{
              input: "text-sm",
            }}
            onChange={(val: any) => {
              setTimeWindow(val);
            }}
          />
        </>
      );
    } if (field.name === "accounts.csm_id" || field.name === "users.csm_id" || field.name === "accounts.ae_id" || field.name === "users.ae_id") {
      return (
        <MemberFilter
          key={`${field?.name} + ${filterId}`}
          fieldSelect={!field?.name}
          handleChange={onCompleteRow}
          op={initOps}
          value={field?.name === initField?.name && initValues}
        />
      );
    } if (field?.data_type === "int" || field?.data_type === "float") {
      return (
        <NumberFilter
          key={`${field?.name} + ${filterId}`}
          fieldSelect={!field?.name}
          handleChange={onCompleteRow}
          op={initOps}
          value={field?.name === initField?.name && initValues}
        />
      );
    } if (field?.data_type === "timestamp" || field?.data_type === "datetime") {
      return (
        <DateFilter
          key={`${field?.name} + ${filterId}`}
          fieldSelect={!field?.name}
          handleChange={onCompleteRow}
          op={initOps}
          value={field?.name === initField?.name && initValues}
        />
      );
    } if (field?.data_type === "boolean") {
      return (
        <BooleanFilter
          key={`${field?.name} + ${filterId}`}
          fieldSelect={!field?.name}
          handleChange={onCompleteRow}
          op={initOps}
          value={field?.name === initField?.name && initValues}
        />
      );
    }
    return (
      <StringFilter
        key={`${field?.name} + ${filterId}`}
        fieldSelect={!field?.name}
        handleChange={onCompleteRow}
        op={initOps}
        value={field?.name === initField?.name && initValues}
        selectedField={field?.name}
      />
    );
  };

  return (
    <div className="flex gap-2 items-center">
      <SingleSelectDropdown
        list={traits}
        type={entity}
        metricsList={metrics}
        selected={field}
        setSelected={setField}
        position="bottom-start"
      />
      {renderFilter()}
      <button
        type="button"
        onClick={() => {
          onRemove(currentKey, filterId);
        }}
        className="inline-flex mt-1 h-7 w-7 items-center text-gray-500 hover:text-purple-500"
      >
        <span className="sr-only">close</span>
        <TrashIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};

export default FilterRow;
