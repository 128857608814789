import { LoadingOverlay } from "@mantine/core";
import { RedirectToLogin, RequiredAuthProvider } from "@propelauth/react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import AccountDetailsPage from "./pages/home/account-details/AccountDetailsPage";
import AccountEvents from "./pages/home/account-details/account-events/AccountEvents";
import AccountOverview from "./pages/home/account-details/account-overview/AccountOverview";
import AccountTraits from "./pages/home/account-details/account-traits/AccountTraits";
import AccountTrends from "./pages/home/account-details/account-trends/AccountTrends";
import AccountUsers from "./pages/home/account-details/account-users/AccountUsers";
import UserDetailsPage from "./pages/home/account-details/user-details/UserDetailsPage";
import AccountsPage from "./pages/home/accounts/AccountsPage";
import GettingStartedPage from "./pages/home/getting-started/GettingStartedPage";
import HeadquartersPage from "./pages/home/headquarters/HeadquartersPage";
import InboxPage from "./pages/home/inbox/InboxPage";
import IntegrationsPage from "./pages/home/settings/IntegrationsPage";
import EditPlaybookPage from "./pages/home/settings/playbooks/EditPlaybookPage";
import NewPlaybookPage from "./pages/home/settings/playbooks/NewPlaybookPage";
import PlaybooksPage from "./pages/home/settings/playbooks/PlaybooksPage";
import SignalsPage from "./pages/home/signals/SignalsPage";
import EditSignalPage from "./pages/home/signals/new-signal/EditSignalPage";
import NewSignalPage from "./pages/home/signals/new-signal/NewSignalPage";
import SignalLogsPage from "./pages/home/signals/signal-log/SignalLogsPage";
import SignalsOverviewPage from "./pages/home/signals/signal-overview/SignalsOverviewPage";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import AddIntegration from "./pages/onboarding/add-integration/AddIntegration";
import NativeApiPage from "./pages/onboarding/native-api/NativeApiPage";
import OnboardingWorkspace from "./pages/onboarding/workspace/OnboardingWorkspace";
import Root from "./pages/root/RootPage";
import NewView from "./pages/viewer/NewView";
import Viewer from "./pages/viewer/Viewer";
import WorkspacesPage from "./pages/workspaces/WorkspacesPage";
import AuthProvider from "./utils/AuthProvider";
import SegmentApiPage from "./pages/onboarding/segment-api/SegmentApiPage";
import NotFoundImage from "./pages/error/NotFoundImage";
import SlackPage from "./pages/onboarding/slack/SlackPage";
import WorkspaceNotFoundPage from "./pages/error/WorkspaceNotFound";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFoundImage />} element={<AuthProvider />}>
      <Route path="/workspace-not-found" element={<WorkspaceNotFoundPage />} />
      <Route path="/workspaces" element={<WorkspacesPage />} />
      <Route path="/viewer" element={<Viewer />} />
      <Route path="/new_view" element={<NewView />} />
      <Route path="/" element={<Root />}>
        <Route path="/onboarding" element={<OnboardingPage />}>
          <Route
            path="workspace/:workspaceId"
            element={<OnboardingWorkspace />}
          />
          <Route
            path="workspace/:workspaceId/integrations"
            element={<AddIntegration />}
          />
          <Route
            path="workspace/:workspaceId/integrations/hyperengage-api"
            element={<NativeApiPage />}
          />
          <Route
            path="workspace/:workspaceId/integrations/segment-api"
            element={<SegmentApiPage />}
          />
        </Route>
        <Route path="/home/:workspaceId" element={<HomePage />}>
          <Route path="getting-started" element={<GettingStartedPage />} />
          <Route path="view/:viewId" element={<AccountsPage />} />
          <Route path="view/:viewId/new" element={<AccountsPage />} />
          <Route path="inbox" element={<InboxPage />} />
          <Route path="account/:accountId" element={<AccountDetailsPage />}>
            <Route path="overview" element={<AccountOverview />} />
            <Route path="traits" element={<AccountTraits />} />
            <Route path="users" element={<AccountUsers />} />
            <Route path="trends" element={<AccountTrends />} />
            <Route path="events" element={<AccountEvents />} />
          </Route>
          <Route
            path="account/:accountId/user/:userId"
            element={<UserDetailsPage />}
          />
          <Route path="user/:userId" element={<UserDetailsPage />} />
          <Route
            path="view/:viewId/account/:accountId"
            element={<AccountDetailsPage />}
          >
            <Route path="overview" element={<AccountOverview />} />
            <Route path="traits" element={<AccountTraits />} />
            <Route path="users" element={<AccountUsers />} />
            <Route path="trends" element={<AccountTrends />} />
            <Route path="events" element={<AccountEvents />} />
          </Route>
          <Route
            path="view/:viewId/account/:accountId/user/:userId"
            element={<UserDetailsPage />}
          />
          <Route
            path="view/:viewId/user/:userId"
            element={<UserDetailsPage />}
          />
          <Route path="headquarters" element={<HeadquartersPage />} />
          <Route path="signals" element={<SignalsPage />}>
            <Route index element={<SignalsOverviewPage />} />
            <Route path="overview" element={<SignalsOverviewPage />} />
            <Route path="logs" element={<SignalLogsPage />} />
          </Route>

          <Route path="signals/new" element={<NewSignalPage />} />
          <Route path="signals/:signalId/edit" element={<EditSignalPage />} />
          <Route path="settings/integrations" element={<IntegrationsPage />} />
          <Route path="settings/integrations/slack" element={<SlackPage />} />
          <Route path="settings/playbooks" element={<PlaybooksPage />} />
          <Route
            path="settings/playbooks/:playbookId/edit"
            element={<EditPlaybookPage />}
          />
          <Route path="settings/playbooks/new" element={<NewPlaybookPage />} />
        </Route>
      </Route>
    </Route>
  )
);

const Main = () => (
  <div className="w-screen h-screen overflow-auto">
    <RequiredAuthProvider
      authUrl={process.env.REACT_APP_AUTH_URL!}
      displayIfLoggedOut={
        <RedirectToLogin postLoginRedirectUrl={window.location.href} />
      }
      displayWhileLoading={
        <LoadingOverlay
          loaderProps={{ size: "lg" }}
          c="main.5"
          visible
          overlayBlur={2}
        />
      }
    >
      <RouterProvider router={router} />
    </RequiredAuthProvider>
  </div>
);

export default Main;
