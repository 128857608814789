/* eslint-disable react/no-unescaped-entities */
import { Card, Grid, Group, Text } from "@mantine/core";
import { useOrgHelper } from "@propelauth/react";
import { useNavigate } from "react-router-dom";
import TopBar from "../onboarding/components/TopBar";
import SignOutMistake from "../../shared/sign-out/SignOutMistake";
import MButton from "../../shared/ui/buttons/MButton";

const WorkspaceCard = ({ workspace }: { workspace: any }) => {
  const navigate = useNavigate();
  return (
    <Grid.Col span={6} style={{ marginBottom: 20 }}>
      <Card
        className="w-[400px] hover:scale-105 cursor-pointer hover:bg-purple-50"
        withBorder
        p="lg"
        onClick={() => {
          localStorage.setItem("he_defws", workspace?.orgId);
          navigate("/");
        }}
        shadow="xs"
      >
        <Card.Section bg="text.1" p="md">
          <Group position="center">
            <Text fz="sm" truncate fw={700}>
              {workspace?.orgName}
            </Text>
          </Group>
        </Card.Section>
        <Card.Section className="flex justify-between py-2 px-4 border-t border-gray-200">
          <div>
            <Text className="text-center" size="xs" color="dimmed">
              Unique Id
            </Text>
            <Text className="text-center" weight={500} size="sm">
              {workspace?.orgMetadata?.unique_identifier}
            </Text>
          </div>
          <div>
            <Text className="text-center" size="xs" color="dimmed">
              Assigned Role
            </Text>
            <Text className="text-center" weight={500} size="sm">
              {workspace?.userAssignedRole}
            </Text>
          </div>
          <div>
            <Text className="text-center" size="xs" color="dimmed">
              Setup
            </Text>
            <Text
              className="text-center"
              weight={500}
              c={
                workspace?.orgMetadata?.setup_complete ? "green.6" : "yellow.6"
              }
              size="sm"
            >
              {workspace?.orgMetadata?.setup_complete ? "Complete" : "Pending"}
            </Text>
          </div>
        </Card.Section>
      </Card>
    </Grid.Col>
  );
};

const WorkspacesPage = () => {
  const orgHelper: any = useOrgHelper();
  const navigate = useNavigate();
  const workspaces = orgHelper?.orgHelper.getOrgs();

  return (
    <div className="w-full h-full bg-gray-100 flex flex-col justify-center items-center">
      <TopBar />
      <section className="flex flex-col rounded-lg border-2 border-gray-200 p-9 max-w-[900px] gap-2 bg-white">
        <h2>Workspaces</h2>
        <h4>
          Workspaces let you collaborate with team members, add permisssions and
          share sources across your whole team under a shared billing account.
        </h4>
        <Grid gutter="md" mt={10}>
          {workspaces?.length > 0 &&
            workspaces.map((workspace: any) => (
              <WorkspaceCard key={workspace.orgId} workspace={workspace} />
            ))}
        </Grid>
        <div className="flex justify-end gap-5 items-center mt-2 -mb-4">
          <SignOutMistake />
          <MButton
            variant="outline"
            c="text.5"
            color="gray"
            className="hover:bg-gray-100"
            size="xs"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </MButton>
        </div>
      </section>
    </div>
  );
};

export default WorkspacesPage;
