/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import uuid from "react-uuid";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import StringDropdown from "../ui/dropdown/StringDropdown";
import BasicSelect from "../ui/inputs/select/BasicSelect";
import FormInput from "../ui/inputs/text/FormInput";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
    selectedField?: any
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
  selectedField?: any;
};

const StringFilter = React.memo((props: Props) => {
  const { op, value, handleChange, fieldSelect, selectedField } = props;

  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const Ops = useMemo(
    () => [
      { name: "is equal to", operator: "eq", id: 1 },
      { name: "is not equal to", operator: "ne", id: 2 },
      { name: "starts with", operator: "starts_with", id: 3 },
      { name: "ends with", operator: "ends_with", id: 4 },
      { name: "contains", operator: "contain", id: 5 },
      { name: "does not contain", operator: "does_not_contain", id: 6 },
      { name: "known", operator: "known", id: 7 },
      { name: "unknown", operator: "unknown", id: 8 },
      { name: "in", operator: "in", id: 9 },
    ],
    []
  );
  const [selectOp, setSelectOp] = useState<any>(
    Ops.find((item) => item.operator === op) || { id: -1, name: "" }
  );
  const [selectValue, setSelectValue] = useState<any>(value || "");
  const [searchValue, setSearchValue] = useState<string>(
    Array.isArray(value) ? "" : value || ""
  );
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(value) ? value : []
  );
  const [inputValue, setInputValue] = useState("");
  const [debouncedVal] = useDebouncedValue(
    selectOp?.operator === "in" ? inputValue : selectValue,
    700
  );
  const [dropdown, setDropdown] = useState<boolean>(false);
  const firstRender = useRef(true);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentElement = inputRef.current;
    if (currentElement) {
      currentElement.addEventListener("click", () => {
        setDropdown(true);
      });
    }

    // Cleanup - remove event listeners
    return () => {
      if (currentElement) {
        currentElement.removeEventListener("click", () => {
          setDropdown(true);
        });
      }
    };
  }, [inputRef]);

  useEffect(() => {
    if (!firstRender.current) {
      if (
        selectOp?.operator === "known" ||
        selectOp?.operator === "unknown" ||
        !!selectOp?.operator
      ) {
        handleChange(
          selectOp.operator,
          selectOp?.operator === "in" ? selectedValues : debouncedVal,
          "string",
          "column"
        );
      }
    }
    firstRender.current = false;
  }, [debouncedVal, selectOp, selectedValues]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      {selectOp?.operator !== "known" && selectOp?.operator !== "unknown" ? (
        selectOp?.operator === "in" ? (
          <Box sx={{ position: "relative", width: "100%" }}>
            <div className="relative">
              <StringDropdown
                isOpen={dropdown}
                setIsOpen={setDropdown}
                multiSelect
                field={selectedField}
                entityType={
                  selectedField?.includes("accounts.") ? "accounts" : "contacts"
                }
                workspaceID={workspaceId}
                value={debouncedVal}
                setValue={(val: any) => {
                  setSelectedValues(val);
                  setInputValue("");
                }}
                selectedValues={selectedValues}
              >
                <div
                  onClick={() => setDropdown(true)}
                  className="flex items-center min-w-[180px] justify-between w-full px-2 py-2 h-full text-sm text-gray-700 border border-gray-300 rounded-md hover:border-gray-400 cursor-pointer bg-white"
                >
                  {selectedValues.length > 0 ? (
                    <div className="flex items-center gap-1">
                      <span className="font-medium whitespace-nowrap">
                        {selectedValues[0]}
                        {selectedValues.length > 1 && ` , ${selectedValues[1]}`}
                        {selectedValues.length > 2 && ` ...`}
                      </span>
                      {selectedValues.length > 2 && (
                        <span className="italic text-[10px] text-gray-400 ml-3">
                          +{selectedValues.length - 2} more
                        </span>
                      )}
                    </div>
                  ) : (
                    <span className="text-gray-400">Select values...</span>
                  )}
                </div>
              </StringDropdown>
            </div>
          </Box>
        ) : selectOp?.operator === "eq" || selectOp?.operator === "ne" ? (
          <StringDropdown
            isOpen={dropdown}
            setIsOpen={setDropdown}
            field={selectedField}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            entityType={
              selectedField?.includes("accounts.") ? "accounts" : "contacts"
            }
            workspaceID={workspaceId}
            value={debouncedVal}
            setValue={setSelectValue}
          >
            <TextInput
              disabled={!selectOp?.operator}
              placeholder="Enter value here"
              classNames={{
                input: "placeholder:text-sm text-sm",
              }}
              id={uuid()}
              value={searchValue}
              type="text"
              onChange={(e) => {
                setDropdown(true);
                setSearchValue(e.target.value);
              }}
            />
          </StringDropdown>
        ) : (
          <FormInput
            disabled={!selectOp?.operator}
            placeHolder="Enter value here"
            className="border-0 placeholder:text-gray-400"
            id={uuid()}
            value={selectValue}
            type="text"
            onChange={(e) => {
              setSelectValue(e.target.value);
            }}
          />
        )
      ) : (
        ""
      )}
    </>
  );
});

export default StringFilter;
