/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { formatISO, fromUnixTime } from "date-fns";
import {
  useTraitsAndMetrics,
  useViews,
  useWorkspaceMembers,
} from "../../hooks/QueryHooks";
import useSelectWorkspace from "../../hooks/useSelectWorkspace";
import LeftSideBar from "./components/LeftSideBar";

const Homepage = () => {
  const [menu, setMenu] = useState<boolean>(true);
  const authInfo: any = useAuthInfo();
  const { data, isLoading } = useSelectWorkspace();

  useWorkspaceMembers({
    workspaceId: data?.id,
    enabled: !!data?.id,
    retry: 2,
  });

  useTraitsAndMetrics({
    workspaceId: data?.id,
    enabled: !!data?.id,
  });

  const views: any = useViews({
    workspaceId: data?.id,
    enabled: !!data?.id,
  });

  const handleMenu = (change: any) => {
    setMenu(change);
  };

  useEffect(() => {
    document.title = "Home | Hyperengage";
  }, []);

  useEffect(() => {
    const selectedWorkspace: any =
      authInfo.orgHelper?.getOrg(data?.id) || undefined;
    if (
      process.env.REACT_APP_HE_WORKSPACE_KEY &&
      process.env.REACT_APP_HE_DATA_KEY &&
      selectedWorkspace?.orgId
    ) {
      window?.hyperengage("identify_account", {
        account_id: selectedWorkspace?.orgId,
        traits: {
          name: selectedWorkspace?.orgName,
          unique_identifier: selectedWorkspace?.orgMetadata?.unique_identifier,
          setup_complete: selectedWorkspace?.orgMetadata?.setup_complete,
          tracking_website_domain: data?.tracking_website_domain,
          workspace_default_currency: data?.workspace_default_currency,
          workspace_time_zone: data?.workspace_time_zone,
          avatar_url: data?.workspace_logo,
          mrr: 0,
          is_trial: true,
          created_at: data?.created_at,
        },
      });
      if (authInfo.user) {
        window?.hyperengage("identify_user", {
          user_id: authInfo.user.userId,
          traits: {
            created_at: formatISO(fromUnixTime(authInfo.user.createdAt)),
            name: `${authInfo.user.firstName} ${authInfo.user.lastName}`,
            first_name: authInfo.user.firstName,
            last_name: authInfo.user.lastName,
            email: authInfo.user.email,
            avatar_url: authInfo.user.pictureUrl,
            properties: authInfo.user?.properties,
            can_create_orgs: authInfo.user?.can_create_orgs,
            mfa_enabled: authInfo.user?.mfa_enabled,
          },
        });
      }
    }
  }, [data?.id]);

  return (
    <>
      <LoadingOverlay
        loaderProps={{ size: "lg" }}
        color="main.5"
        visible={views.isLoading || isLoading}
        overlayBlur={2}
      />
      <LeftSideBar
        menu={menu}
        workspace={data}
        setMenu={handleMenu}
        views={views?.data || [{ text: "empty", to: "/" }]}
      />
      <div
        className={`relative ease-linear duration-100 ${
          menu ? "ml-[260px]" : "ml-[75px]"
        } flex flex-col h-full`}
      >
        {views?.data?.length && <Outlet />}
      </div>
    </>
  );
};

export default Homepage;
