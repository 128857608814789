/* eslint-disable react-hooks/exhaustive-deps */
import { useOrgHelper } from "@propelauth/react";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWorkspaceStore from "../store/workspace/workspaceStore";
import { useCurrentWorkspace } from "./QueryHooks";

const useSelectWorkspace = () => {
  const { setWorkspace } = useWorkspaceStore();
  const uid = useParams();
  const orgs = useOrgHelper().orgHelper?.getOrgs();
  const selectedWorkspace: any = useMemo(
    () =>
      orgs?.find(
        (item: any) => item?.orgMetadata?.unique_identifier === uid?.workspaceId
      ) || undefined,
    [orgs?.length, uid?.workspaceId]
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (orgs && orgs.length && !selectedWorkspace) {
      navigate("/workspace-not-found", { replace: true });
    }
  }, [orgs?.length, selectedWorkspace]);

  const workspaceData: any = useCurrentWorkspace({
    workspaceId: selectedWorkspace?.orgId,
    enabled: !!selectedWorkspace?.orgId,
  });

  useEffect(() => {
    if (workspaceData?.isSuccess) {
      localStorage.setItem("he_defws", workspaceData?.data?.id);
      setWorkspace(workspaceData.data);
    }
  }, [workspaceData?.isSuccess, workspaceData?.data?.id]); // Add workspaceData as a dependency

  return workspaceData;
};

export default useSelectWorkspace;
