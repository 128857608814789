/* eslint-disable no-nested-ternary */
import { BuildingOffice2Icon, UserCircleIcon } from "@heroicons/react/20/solid";
import { Select, Text } from "@mantine/core";
import {
  useLayoutEffect, useMemo,
  useState,
} from "react";
import FilterRow from "./FilterRow";

type Props = {
  onRemove: (currentKey: any) => void;
  currentKey: number;
  traitsAndMetrics: any,
  filterId: number,
  time_window?: string;
  onCompleteFilter: (field: any,
    operator: any,
    value: any,
    type: any,
    entityType: any,
    id: number, filterId: number, time_window?: string,) => void;
  filter: any;
  entityRelation?: "accounts" | "users";
};

const typeChoices = [
  { value: "accounts", label: "Accounts", Icon: BuildingOffice2Icon },
  { value: "users", label: "Users", Icon: UserCircleIcon },
];

const RowEntitySelect = (
  {
    time_window,
    onRemove,
    filter,
    traitsAndMetrics,
    currentKey,
    onCompleteFilter,
    filterId,
    entityRelation,
  }: Props,
) => {
  const [entity, setEntity] = useState<any>(filter?.field
    ? (/metric/.test(filter?.field) ? entityRelation : (filter.field.startsWith("users.") ? "users" : "accounts")) : entityRelation);
  const traits: any = useMemo(() => (entity === "accounts" ? traitsAndMetrics?.accountTraits : traitsAndMetrics?.contactTraits), [entity, traitsAndMetrics?.accountTraits, traitsAndMetrics?.contactTraits]);
  const metrics: any = useMemo(() => (entity === "accounts" ? traitsAndMetrics?.accountMetrics : (entity === "users" ? traitsAndMetrics?.contactMetrics : [])), [entity, traitsAndMetrics?.accountMetrics, traitsAndMetrics?.contactMetrics]);

  const allColumns = { ...traits?.obj, ...metrics?.obj };

  useLayoutEffect(() => {
    if (filter?.field) {
      if (traitsAndMetrics?.accountTraits?.obj[filter.field]
        || traitsAndMetrics?.accountMetrics?.obj[filter.field]) {
        setEntity("accounts");
      } else if (traitsAndMetrics?.contactTraits?.obj[filter.field]
        || traitsAndMetrics?.contactMetrics?.obj[filter.field]) {
        setEntity("users");
      }
    }
  }, [filter.field,
    traitsAndMetrics?.accountMetrics?.obj,
    traitsAndMetrics?.accountTraits?.obj,
    traitsAndMetrics?.contactMetrics?.obj,
    traitsAndMetrics?.contactTraits?.obj]);

  return (
    <div className="flex gap-2 items-center">
      <Text
        c="text.5"
        mr={5}
        ml={currentKey === 0 ? 0 : 15}
        size="xs"
      >
        {currentKey === 0 ? "Where" : "And"}
      </Text>
      <Select value={entity} data={typeChoices} size="sm" onChange={(value: any) => setEntity(value)} />
      <FilterRow
        entity={entity}
        key={`${entity || entityRelation} + ${filter?.field}`}
        initField={filter?.field && {
          name: allColumns[filter.field]?.name,
          display_name: allColumns[filter.field]?.display_name,
          id: 5000,
          attribute_type: allColumns[filter.field]?.attribute_type,
          entity_type: filter?.entity_type,
          data_type: allColumns[filter.field]?.data_type,
        }}
        initOps={filter?.operator}
        time_window={time_window}
        single_time_window={filter?.time_window}
        initValues={filter.value}
        onRemove={onRemove}
        traits={traits?.list?.filter((item: any) => !item.exclude_display)}
        metrics={metrics?.list?.filter((item: any) => !item.exclude_display)}
        currentKey={currentKey}
        filterId={filterId}
        onCompleteFilter={onCompleteFilter}
      />
    </div>
  );
};

export default RowEntitySelect;
