import {
  ArrowRightOnRectangleIcon,
  ChevronUpDownIcon,
  Cog6ToothIcon,
  SquaresPlusIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { CheckIcon, Menu, ScrollArea } from "@mantine/core";
import {
  useHostedPageUrls,
  useLogoutFunction,
  useOrgHelper,
} from "@propelauth/react";
import { IconBook } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserButton from "./UserButton";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";

const AvatarMenu = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  desc,
  img,
  menu,
}: any) => {
  const logout = useLogoutFunction();
  const { getOrgPageUrl, getAccountPageUrl } = useHostedPageUrls();
  const ws = useWorkspaceStore((state) => state.workspace);
  const [opened, setOpened] = useState<boolean>(false);
  const params = useParams();
  const orgHelper: any = useOrgHelper();
  const navigate = useNavigate();
  const workspaces = orgHelper?.orgHelper.getOrgs();
  return (
    <Menu
      classNames={{
        item: "text-gray-700",
      }}
      shadow="md"
      width={250}
      opened={opened}
      onChange={setOpened}
      closeOnClickOutside
    >
      <Menu.Target>
        <div>
          <UserButton
            minimized={!menu}
            icon={<ChevronUpDownIcon className="w-4 h-4 text-gray-500" />}
            name={name}
            email={desc}
            image={img}
          />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Workspaces</Menu.Label>
        <ScrollArea.Autosize scrollbarSize={8} type="always" mah={100}>
          {workspaces?.map((workspace: any) => (
            <Menu.Item
              rightSection={
                params?.workspaceId ===
                  workspace?.orgMetadata.unique_identifier && (
                  <CheckIcon
                    className="text-purple-700"
                    width={12}
                    height={12}
                  />
                )
              }
              className={`${
                params?.workspaceId === workspace?.orgMetadata.unique_identifier
                  ? "bg-purple-100"
                  : ""
              }`}
              key={workspace?.orgId}
              onClick={() => {
                localStorage.setItem("he_defws", workspace?.orgId);
                navigate("/", { state: { refresh: true } });
              }}
            >
              {workspace?.orgName}
            </Menu.Item>
          ))}
        </ScrollArea.Autosize>
        <Menu.Divider />
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item
          onClick={() => {
            window.location.href = getAccountPageUrl();
          }}
          icon={<Cog6ToothIcon width={16} height={16} />}
        >
          Workspace Settings
        </Menu.Item>
        <Menu.Item
          icon={<SquaresPlusIcon width={16} height={16} />}
          onClick={() => {
            navigate(`/home/${params.workspaceId}/settings/integrations`);
          }}
        >
          Integrations
        </Menu.Item>
        <Menu.Item
          icon={<IconBook width={16} height={16} />}
          onClick={() => {
            navigate(`/home/${params.workspaceId}/settings/playbooks`);
          }}
        >
          Playbooks
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>Account</Menu.Label>
        <Menu.Item
          icon={<UserCircleIcon width={16} height={16} />}
          onClick={() => {
            window.location.href = getOrgPageUrl(ws?.id);
          }}
        >
          Profile
        </Menu.Item>
        <Menu.Item
          icon={<ArrowRightOnRectangleIcon width={16} height={16} />}
          onClick={() => {
            logout(true);
          }}
          className="text-red-600"
        >
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AvatarMenu;
