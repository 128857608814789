/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Loader,
  Popover,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import { IconCheck, IconSearch, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { getTypeAhead } from "../../../services/typeAheadEndpoint";

type Props = {
  value: any;
  setValue: any;
  field: string;
  workspaceID: string;
  entityType: "accounts" | "contacts";
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  selectedValues?: any[];
  multiSelect?: boolean;
  searchValue?: any;
  setSearchValue?: any;
};

const StringDropdown = ({
  value,
  setValue,
  setSearchValue,
  searchValue,
  field,
  workspaceID,
  entityType,
  children,
  isOpen,
  setIsOpen,
  selectedValues = [],
  multiSelect = false,
}: Props) => {
  const [loaderData, setLoaderData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const temp = await getTypeAhead({
          selector_field: field,
          entity_type: entityType === "accounts" ? "accounts" : "users",
          search_query: searchValue || search || "",
          workspace_identifier: workspaceID,
        });
        setLoaderData(
          temp?.data?.results?.map((item: any) => ({
            value: item.column_value,
            label: item.column_value,
            count: item.occurrence_count,
          })) || []
        );
      } catch (e) {
        setLoaderData([]);
      }
      setLoading(false);
    };
    if (entityType && workspaceID && field) {
      getData();
    }
  }, [value, entityType, workspaceID, field, search, searchValue]);

  return (
    <Popover
      opened={isOpen}
      onChange={setIsOpen}
      width="target"
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <div className="w-full cursor-pointer">{children}</div>
      </Popover.Target>
      <Popover.Dropdown className=" rounded-lg min-w-[300px]">
        <div className="flex gap-5 items-center w-full flex-col">
          {multiSelect && (
            <div className="flex items-center flex-wrap gap-1 rounded-md w-full px-2 py-1 border bg-gray-50">
              <TextInput
                size="xs"
                rightSection={
                  <IconSearch size={rem(15)} className="text-gray-500" />
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                classNames={{
                  input:
                    "!focus:shadow-none !focus:ring-0 !focus:outline-none !ring-0 !placeholder:text-xs !text-xs",
                }}
                className="w-full !focus:shadow-none !focus:ring-0 !focus:outline-none"
              />
              {selectedValues.map((val) => (
                <div className="bg-white text-ellipsis overflow-hidden text-gray-700 border border-gray-200 rounded px-2 py-0.5 flex items-center gap-1 text-sm whitespace-nowrap">
                  <span className="text-[10px] text-ellipsis overflow-hidden line-clamp-1 max-w-[150px]">
                    {val}
                  </span>
                  <ActionIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(selectedValues.filter((item) => item !== val));
                    }}
                    size="xs"
                    className="text-red-500 hover:text-purple-700 shrink-0"
                  >
                    <IconX size={rem(10)} />
                  </ActionIcon>
                </div>
              ))}
            </div>
          )}
          <div
            className={`w-full flex items-center justify-between px-2 ${
              multiSelect ? "-my-3" : "-mb-3"
            }`}
          >
            <Text className="text-xs ] text-gray-900 font-semibold">
              {
                loaderData?.filter(
                  (item: any) => !selectedValues.includes(item.value)
                )?.length
              }{" "}
              results
            </Text>
          </div>
          <div className="flex flex-col gap-0.5 w-full bg-gray-100 border-gray-100 border-2 rounded-md max-h-[240px] overflow-y-auto">
            {loading ? (
              <Loader size="xs" className="mx-auto my-2" />
            ) : loaderData?.length ? (
              loaderData.map((filterItem: any) => (
                <div
                  key={filterItem.value}
                  onClick={(e) => {
                    if (!multiSelect) {
                      setValue(filterItem.value);
                      setSearchValue?.(filterItem.value);
                      setIsOpen(false);
                      return;
                    }
                    e.stopPropagation();
                    const isSelected = selectedValues.includes(
                      filterItem.value
                    );
                    setValue(
                      isSelected
                        ? selectedValues.filter(
                            (val) => val !== filterItem.value
                          )
                        : [...selectedValues, filterItem.value]
                    );
                  }}
                  className="px-3 py-2 w-full bg-white text-sm text-gray-700 hover:bg-gray-50 cursor-pointer transition-colors duration-150 flex items-center justify-between border-b border-gray-100 last:border-b-0"
                >
                  <span>{filterItem.value}</span>
                  {selectedValues.includes(filterItem.value) && (
                    <IconCheck size={rem(16)} className="text-purple-500" />
                  )}
                </div>
              ))
            ) : (
              <div className="px-3 py-2 text-sm text-gray-500 italic">
                No results
              </div>
            )}
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default StringDropdown;
