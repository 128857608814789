/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthInfo } from "@propelauth/react";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Viewer = () => {
  const authInfo = useAuthInfo();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      searchParams.get("external_id") &&
      searchParams.get("env_id") &&
      searchParams.get("entity_relation")
    ) {
      const selectedWorkspace: any = authInfo.orgHelper?.getOrg(
        searchParams.get("env_id") || ""
      );
      if (selectedWorkspace) {
        return navigate(
          `/home/${selectedWorkspace?.orgMetadata?.unique_identifier}/${
            searchParams.get("entity_relation") === "accounts"
              ? "account"
              : "user"
          }/${searchParams.get("external_id")}${
            searchParams.get("entity_relation") === "accounts"
              ? "/overview"
              : ""
          }`
        );
      }
    }
    return navigate("/");
  }, [searchParams]);
  return <div />;
};

export default Viewer;
