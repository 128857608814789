/* eslint-disable max-len */
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatISO, fromUnixTime } from "date-fns";
import { useCurrentUser, useCurrentWorkspace } from "../../hooks/QueryHooks";
import { getWorkspace } from "../../services/workspace/workspaceEndpoints";
import notify from "../../utils/notify";
import LoadWorkspace from "./components/LoadWorkspace";

const Root = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const tries = useRef<any>(0);
  const params = useParams();
  const authInfo = useAuthInfo();
  const authRoutes = useRedirectFunctions();
  const heDef = localStorage.getItem("he_defws") || "";
  const ws: any = authInfo?.orgHelper?.getOrgs();

  useCurrentUser({});

  useCurrentWorkspace({
    workspaceId: heDef,
    enabled: !authInfo.loading && !!heDef,
    retry: false,
  });

  useEffect(() => {
    if (authInfo.loading) {
      return;
    }
    if (location.pathname.includes("viewer")) {
      return;
    }
    const selectedWorkspace: any =
      authInfo.orgHelper?.getOrg(heDef) || undefined;
    if (location?.state?.setupComplete && tries.current === 0) {
      setLoading(true);
      const getData = async () => {
        const delay = 2 * 6 ** tries.current;
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, delay));
        await authInfo.refreshAuthInfo();

        tries.current += 1;
        setLoading(false);
      };
      getData();
      return;
    }
    if (params?.workspaceId) {
      return;
    }
    if (!authInfo.loading && tries.current < 5) {
      if (!ws?.length) {
        authRoutes.redirectToCreateOrgPage();
        return;
      }

      if (!selectedWorkspace) {
        if (ws?.length > 1) {
          localStorage.removeItem("he_defws");
          navigate("/workspaces");
          return;
        }
        if (!ws[0]?.orgMetadata?.unique_identifier) {
          setLoading(true);
          const getWsData: any = async (count: any) => {
            try {
              const newWorkspace = await getWorkspace({
                workspace_id: ws[0].orgId,
              });
              if (!newWorkspace.data) {
                notify({
                  heading: "Error",
                  messageList: [
                    "Something went wrong. Sorry for the inconvenience, kindly contact support to help you resolve this issue",
                  ],
                  type: "failure",
                });
              }
              localStorage.setItem("he_defws", newWorkspace.data.id);
              await authInfo.refreshAuthInfo();

              tries.current += 1;
            } catch {
              if (count <= 3) {
                // Calculate exponential delay
                const delay = 2 * 2 ** count;
                // Wait for a bit before retrying
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((resolve) => setTimeout(resolve, delay));
                // Try fetching again
                // eslint-disable-next-line consistent-return
                return getWsData(count + 1);
              }
              notify({
                heading: "Error",
                messageList: [
                  "Something went wrong. Sorry for the inconvenience, kindly contact support to help you resolve this issue",
                ],
                type: "failure",
              });
            }
            return true;
          };
          getWsData(1);
          return;
        }
        localStorage.setItem("he_defws", ws[0]?.orgId);
        if (
          process.env.REACT_APP_HE_WORKSPACE_KEY &&
          process.env.REACT_APP_HE_DATA_KEY
        ) {
          window?.hyperengage("identify_account", {
            account_id: ws[0]?.orgId,
            traits: {
              name: ws[0]?.orgName,
              unique_identifier: ws[0]?.orgMetadata?.unique_identifier,
              setup_complete: ws[0]?.orgMetadata?.setup_complete,
              mrr: 0,
              is_trial: true,
            },
          });
          if (authInfo.user) {
            window?.hyperengage("identify_user", {
              user_id: authInfo.user.userId,
              traits: {
                created_at: formatISO(fromUnixTime(authInfo.user.createdAt)),
                name: `${authInfo.user.firstName} ${authInfo.user.lastName}`,
                email: authInfo.user.email,
                first_name: authInfo.user.firstName,
                last_name: authInfo.user.lastName,
                avatar_url: authInfo.user.pictureUrl,
                properties: authInfo.user?.properties,
                can_create_orgs: authInfo.user?.canCreateOrgs,
                mfa_enabled: authInfo.user?.mfaEnabled,
              },
            });
          }
        }
        if (!ws[0]?.orgMetadata?.setup_complete) {
          navigate(
            `/onboarding/workspace/${ws[0].orgMetadata.unique_identifier}`
          );
        } else {
          navigate(
            `/home/${ws[0]?.orgMetadata?.unique_identifier}/headquarters`
          );
        }
        return;
      }
      if (!selectedWorkspace?.orgMetadata?.unique_identifier) {
        console.log("selectedWorkspace", selectedWorkspace);
        const getData = async () => {
          const delay = 2 * 6 ** tries.current;
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, delay));
          await authInfo.refreshAuthInfo();
          tries.current += 1;
        };
        getData();
        return;
      }
      if (!selectedWorkspace?.orgMetadata?.setup_complete) {
        setLoading(false);
        if (
          process.env.REACT_APP_HE_WORKSPACE_KEY &&
          process.env.REACT_APP_HE_DATA_KEY
        ) {
          window?.hyperengage("identify_account", {
            account_id: selectedWorkspace?.orgId,
            traits: {
              name: selectedWorkspace?.orgName,
              unique_identifier:
                selectedWorkspace?.orgMetadata?.unique_identifier,
              setup_complete: selectedWorkspace?.orgMetadata?.setup_complete,
              mrr: 0,
              is_trial: true,
            },
          });
          if (authInfo.user) {
            window?.hyperengage("identify_user", {
              user_id: authInfo.user.userId,
              traits: {
                created_at: formatISO(fromUnixTime(authInfo.user.createdAt)),
                name: `${authInfo.user.firstName} ${authInfo.user.lastName}`,
                email: authInfo.user.email,
                first_name: authInfo.user.firstName,
                last_name: authInfo.user.lastName,
                avatar_url: authInfo.user.pictureUrl,
                properties: authInfo.user?.properties,
                can_create_orgs: authInfo.user?.canCreateOrgs,
                mfa_enabled: authInfo.user?.mfaEnabled,
              },
            });
          }
        }
        navigate(
          `/onboarding/workspace/${selectedWorkspace.orgMetadata.unique_identifier}`
        );
        return;
      }
      if (!params?.workspaceId) {
        setLoading(false);
        navigate(
          `/home/${selectedWorkspace.orgMetadata.unique_identifier}/headquarters`
        );
      }
    }
    if (tries.current >= 3) {
      setLoading(false);
      notify({
        heading: "Error",
        messageList: [
          "Something went wrong while updating the workspace. Sorry for the inconvenience, kindly contact support to help you resolve this issue",
        ],
        type: "failure",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo.loading, location?.state?.refresh, tries.current]);

  if (authInfo.loading) {
    return (
      <>
        <Outlet />
        <LoadWorkspace />
      </>
    );
  }

  return (
    <>
      <Outlet />
      {loading && <LoadWorkspace />}
    </>
  );
};

export default Root;
